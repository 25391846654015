import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../components/Login.vue";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/form/novo",
    name: "Form",
    component: () => import("../components/archive/Form.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/projects",
    name: "Projects",
    component: () => import("../pages/projects/index.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/projects/:id",
    name: "EditProject",
    component: () => import("../pages/projects/edit.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/projects/dna/:id",
    name: "ProjectDna",
    component: () => import("../pages/projects/dna.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/projects/report/:id",
    beforeEnter: (to) => {
      const idToken = localStorage.idToken;
      if (idToken) {
        const baseUrl = location.hostname.toString().includes("calculadora")
          ? "https://api.calculadoracultural.com.br"
          : "http://localhost:3000";

        console.log("beforeEnter", to.params.id, idToken);
        window.location.assign(
          `${baseUrl}/project/${to.params.id}/report?it=${idToken}`
        );
      } else {
        // Redirect to login if not authenticated
        router.push("/login");
      }
    },
  },
  {
    path: "*",
    redirect: "/projects",
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const idToken = localStorage.idToken;

  if (requiresAuth && !idToken) {
    debugger;
    next({ path: "/login" });
  } else if (idToken) {
    // If authenticated, update the store with user data
    const userData = JSON.parse(localStorage.user || "{}");

    store.commit("setProfile", { user: userData });
    store.commit("setToken", idToken);
    next();
  } else {
    next();
  }
});

export default router;
