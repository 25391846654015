import Vue from "vue";
import VueCookies from "vue-cookies";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import "./plugins/vuetify-mask.js";
import VueRamda from "vue-ramda";
import axios from "axios";
import store from "./store";
import moment from "moment";

Vue.use(VueRamda);
Vue.use(VueCookies);

Vue.config.productionTip = false;
Vue.config.devtools = process.env.NODE_ENV === "development";

Vue.use({
  install(Vue) {
    Vue.prototype.$moment = moment;
    Vue.prototype.$api = function() {
      const idToken = localStorage.idToken;
      if (idToken) {
        const instance = axios.create({
          baseURL: location.hostname.toString().includes("calculadora")
            ? "https://api.calculadoracultural.com.br"
            : "http://localhost:3000",
          headers: {
            common: {
              it: idToken,
            },
          },
        });

        // Adiciona um interceptor de resposta para redirecionar em caso de erro 403
        instance.interceptors.response.use(
          (response) => response,
          (error) => {
            if (error.response && error.response.status === 403) {
              // Define o caminho atual como redirecionamento e direciona para o login
              const redirPath =
                (!location.pathname.includes("login") && location.pathname) ||
                null;
              this.$store.commit("setRedirect", redirPath);
              router.push("/login");
            }
            return Promise.reject(error);
          }
        );

        return instance;
      } else {
        const redirPath =
          (!location.pathname.includes("login") && location.pathname) || null;
        this.$store.commit("setRedirect", redirPath);
        router.push("/login");
        return null;
      }
    };
  },
});
new Vue({
  vuetify,
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
