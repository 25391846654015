<template>
  <v-app v-cloak>
    <link
      href="https://cdn.jsdelivr.net/npm/@mdi/font@4.x/css/materialdesignicons.min.css"
      rel="stylesheet"
    />
    <link
      href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700|Material+Icons"
      rel="stylesheet"
    />
    <link
      href="https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,300;0,900;1,400&display=swap"
      rel="stylesheet"
    />
    <v-main>
      <v-app-bar app color="#F1C732" class="no-print">
        <template v-if="profile && profile.user">
          <v-app-bar-nav-icon
            color="#E8452A"
            @click.stop="toggleDrawer()"
          ></v-app-bar-nav-icon>
        </template>
        <router-link to="/">
          <v-img
            max-height="140"
            max-width="120"
            contain
            class="mx-2"
            src="https://wws.calculadoracultural.com.br/wp-content/uploads/2021/04/WP-02.png"
          >
          </v-img>
        </router-link>
        <v-spacer></v-spacer>
        <v-toolbar color="#F1C732" flat v-if="$route.params.id">
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            color="primary"
            class="mx-1"
            :to="`/projects/${$route.params.id}`"
          >
            <v-icon>mdi-calculator-variant</v-icon>
          </v-btn>
          <v-btn
            icon
            small
            color="primary"
            class="mx-1"
            :to="`/projects/dna/${$route.params.id}`"
          >
            <v-icon>mdi-dna</v-icon>
          </v-btn>
          <v-btn
            icon
            small
            color="primary"
            class="mx-1 mr-3"
            :to="`/projects/report/${$route.params.id}`"
          >
            <v-icon>mdi-chart-box-outline</v-icon>
          </v-btn>
        </v-toolbar>
        <template v-if="profile && profile.user">
          <v-avatar size="36px" @click.stop="rightDrawer = !rightDrawer">
            <v-icon>mdi-account</v-icon>
          </v-avatar>
        </template>
      </v-app-bar>
      <template v-if="profile && profile.user">
        <v-navigation-drawer v-model="drawer" fixed left temporary>
          <v-list nav dense>
            <router-link to="/">
              <v-img
                @click="drawer = false"
                v-touch="{
                  left: () => (drawer = false),
                }"
                max-height="140"
                max-width="120"
                contain
                class="mx-2"
                src="https://wws.calculadoracultural.com.br/wp-content/uploads/2021/04/WP-02.png"
              >
              </v-img>
            </router-link>
            <v-divider class="my-6" />
            <v-list-item-group ref="menu" active-class="primary--text">
              <v-list-item class="font-weight-bold">
                Início
              </v-list-item>
              <v-divider class="my-2" />
              <v-list-item exact to="/projects">
                <v-list-item-icon>
                  <v-icon>mdi-calendar-multiple</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Projetos</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item exact to="/projects/new">
                <v-list-item-icon>
                  <v-icon>mdi-plus</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Criar projeto</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
            <v-list-item-group v-if="$route.params.id">
              <v-list-item class="font-weight-bold">
                Projeto
              </v-list-item>
              <v-divider class="my-2" />
              <v-list-item :to="`/projects/${$route.params.id}`">
                <v-list-item-icon>
                  <v-icon>mdi-calculator-variant</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Ir para Calculadora</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item :to="`/projects/dna/${$route.params.id}`">
                <v-list-item-icon>
                  <v-icon>mdi-dna</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Analizar DNA</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item :to="`/projects/report/${$route.params.id}`">
                <v-list-item-icon>
                  <v-icon>mdi-chart-box-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Relatório</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="my-2" />
            </v-list-item-group>
          </v-list>
        </v-navigation-drawer>

        <v-navigation-drawer absolute temporary right v-model="rightDrawer">
          <template v-slot:prepend>
            <v-list-item two-line>
              <v-list-item-avatar>
                <v-avatar size="36px" @click.stop="rightDrawer = !rightDrawer">
                  <v-icon>mdi-account</v-icon>
                </v-avatar>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>{{
                  profile.user && profile.user.name
                }}</v-list-item-title>
                <v-list-item-subtitle>Ativo</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>

          <v-divider></v-divider>

          <v-list dense>
            <v-list-item @click.stop="logOut()">
              <v-list-item-icon>
                <v-icon>mdi-exit-run</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Sair</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>
      </template>

      <div class="wrapper">
        <router-view></router-view>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import router from "./router";

import { mapGetters } from "vuex";

export default {
  name: "App",
  computed: {
    ...mapGetters(["profile"]),
  },
  methods: {
    toggleDrawer() {
      this.drawer = !this.drawer;
      if (this.drawer) {
        this.$vuetify.goTo(this.$refs.menu);
      }
    },
    logOut() {
      localStorage.removeItem("idToken");
      localStorage.removeItem("user");
      this.$store.commit("setProfile", {});
      this.$store.commit("setToken", {});

      router.push({
        name: "Login",
      });
    },
  },
  mounted() {
    var _vm = this;
    setInterval(() => {
      if (_vm.profile && _vm.profile.user) {
        _vm.counter++;
      }
    }, 60000);
  },
  data: () => ({
    //
    drawer: false,
    rightDrawer: false,
    statusInfo: 0,
    counter: 0,
  }),
};
</script>

<style lang="scss">
:root {
  overflow: hidden;
}

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}

html,
body {
  width: 100%;
  height: auto;
  font-size: 16px;
  overflow: hidden;
}

.wrapper {
  height: calc(100vh - 64px);
  width: 100%;
  overflow-y: visible !important;
  overflow-x: hidden !important;
  padding-bottom: 10vh;
  margin: 0 auto;

  @media only screen and (max-width: 768px) {
    height: calc(100vh - 56px);
  }
}

.v-select .v-select__slot > label {
  font-size: 0.9rem;
}

.v-data-footer {
  padding: 0;
}

.absolute {
  position: absolute;
}

.h-full {
  height: 100%;
}

/* custom scrollbars */
::-webkit-scrollbar-thumb {
  background: rgba(100, 100, 255, 0.3);
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar {
  padding: 0 3px;
  height: 0.5em;
  width: 0.5em;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  box-shadow: 0 0 0px 1000px white inset;
  transition: background-color 3000s ease-in-out 0s;
}
</style>
