<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" md="4">
        <v-card class="pa-4">
          <v-card-title class="justify-center">Identificação</v-card-title>
          <v-card-subtitle class="text-center">
            Por favor, insira seu e-mail para receber o código e completar seu
            login.
          </v-card-subtitle>

          <v-form ref="form" v-model="valid" class="mt-3">
            <v-text-field
              v-model="email"
              :rules="emailRules"
              label="Email"
              required
            ></v-text-field>

            <v-btn
              class="mt-3"
              @click="sendOtpInit"
              :disabled="!valid || isSendingOtp"
              :loading="isSendingOtp"
              block
              v-if="!otpInputEnabled"
            >
              Prosseguir
            </v-btn>
            <v-alert v-if="message" type="success" dismissible class="mt-3">{{
              message
            }}</v-alert>

            <v-text-field
              v-if="otpInputEnabled"
              v-model="otpCode"
              :rules="otpRules"
              label="Insira aqui o código"
              required
              class="mt-3"
            ></v-text-field>

            <v-btn
              v-if="otpInputEnabled"
              class="mt-3"
              @click="verifyOtp"
              :disabled="!otpCodeValid || isVerifyingOtp"
              :loading="isVerifyingOtp"
              block
            >
              Entrar
            </v-btn>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import router from "../router";

const API_URL = location.hostname.toString().includes("calculadora")
  ? "https://api.calculadoracultural.com.br"
  : "http://localhost:3000";

export default {
  data() {
    return {
      email: "",
      otpCode: "",
      message: "",
      valid: false,
      otpInputEnabled: false,
      emailRules: [
        (v) => !!v || "E-mail é obrigatório",
        (v) => /.+@.+\..+/.test(v) || "E-mail deve ser válido",
      ],
      otpRules: [
        (v) => !!v || "Código é obrigatório",
        (v) => /^\d{4}$/.test(v) || "Código deve ter 4 dígitos",
      ],
      isSendingOtp: false,
      isVerifyingOtp: false,
    };
  },
  computed: {
    otpCodeValid() {
      return /^\d{4}$/.test(this.otpCode);
    },
    isChrome() {
      const userAgent = navigator.userAgent;
      const isChrome = /chrome|chromium/i.test(userAgent);
      return isChrome;
    },
  },
  methods: {
    sendOtpInit() {
      if (this.$refs.form.validate()) {
        this.isSendingOtp = true; // Iniciar loading
        axios
          .post(`${API_URL}/otp/init`, { email: this.email })
          .then((response) => {
            if (response.data.ok) {
              this.message =
                "Um código de validação foi enviado para o seu e-mail";
              this.otpInputEnabled = true;
            } else {
              this.message = "Falha ao enviar código de validação";
            }
          })
          .catch((error) => {
            this.message = "Erro ao enviar solicitação";
            console.error(error);
          })
          .finally(() => {
            this.isSendingOtp = false; // Parar loading
          });
      }
    },
    verifyOtp() {
      this.isVerifyingOtp = true; // Iniciar loading
      axios
        .post(`${API_URL}/otp/verify`, {
          email: this.email,
          otp: this.otpCode,
        })
        .then((response) => {
          if (response.data.ok) {
            const token = response.data.token;
            localStorage.idToken = token;
            localStorage.user = JSON.stringify({ name: this.email });

            let userData = {
              idToken: token,
              user: {
                name: this.email,
                image: "",
              },
            };

            this.$store.commit("setToken", token);
            this.$store.commit("setProfile", userData);

            router.push("/");
          } else {
            this.message = "Código inválido ou expirado";
          }
        })
        .catch((error) => {
          this.message = "Erro ao verificar código OTP";
          console.error(error);
        })
        .finally(() => {
          this.isVerifyingOtp = false; // Parar loading
        });
    },
  },
};
</script>
